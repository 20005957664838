<template>
  <DocumentCenterModal
    :download="download"
    :open="open"
    @close-modal="closeModal()"
    @after-close-modal="afterCloseModal()"
  />
</template>

<script setup lang="ts">
import DocumentCenterModal from '~/components/downloads/documents/DocumentCenterModal.vue';
import { useDownloadStore } from '~/stores/downloadStore/downloadStore';
import { TYPE } from 'vue-toastification';

import type {
  DownloadByIdQueryModel,
  DownloadByIdResponseModel
} from '~/server/api/downloads/byId.get';

const { $resolvedLocale, $textDictionary } = useNuxtApp();
const logger = useLogger();
const toasts = useShowToast();

const route = useRoute();
const router = useRouter();
const store = useDownloadStore();

const open = ref(false);
const downloadId = computed<string | undefined>(
  () => route.hash?.split('download=')?.[1]
);

// XXX: forward loading state?
const { data: download, error } = await useAsyncData(
  async () => {
    if (downloadId.value) {
      const cachedDownload = Object.values(store.downloads?.results ?? [])
        .map((result) => result.hits)
        .flat()
        .find((hit) => hit.id === downloadId.value);

      return (
        cachedDownload ??
        (await $fetch<DownloadByIdResponseModel>('/api/downloads/byId', {
          method: 'get',
          query: {
            id: downloadId.value,
            locale: $resolvedLocale.value!
          } satisfies DownloadByIdQueryModel
        }))
      );
    }

    return null;
  },
  {
    deep: false,
    lazy: true,
    watch: [downloadId]
  }
);

watch(error, () => {
  if (error.value) {
    const message =
      $textDictionary.value['downloadCenter.modal.display.error'] ??
      `Failed to display download modal for download with id ${downloadId.value}`;

    toasts.showToast(message, {
      type: TYPE.ERROR
    });

    logger.error(
      `error during download fetch in document center standalone modal for id ${downloadId.value}`,
      error.value
    );
  }
});

watch(download, () => {
  // only open download if download id is given
  // download can actually be `null` (e.g. for 404 or other errors).
  if (downloadId.value) {
    open.value = true;
  }
});

function closeModal() {
  open.value = false;
}

function afterCloseModal() {
  // closeModal is not triggered while closing the modal via esc...
  // so we also have to set the open value here.
  open.value = false;

  router.push({
    hash: undefined,
    query: route.query
  });
}
</script>
